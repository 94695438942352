<template>
    <div class="container">
        <h1>Web Radar</h1>
        <v-btn @click="getKeys" v-if="reconnect" color="primary">Re-connect</v-btn>
        <p v-if="error" style="color: red;">Error: {{ error }}</p>
        <template v-if="socket">

            <template v-if="Object.keys(data).length > 0">
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Code
                        </th>
                        <th class="text-left">
                            Key
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="key in Object.keys(data)" :key="key"
                        >
                        <td>{{ key }}</td>
                        <td>{{ data[key] }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </template>
            <template v-else>
                <p>Waiting data<span class="loading-dots">...</span> </p>
            </template>
        </template>
        <template v-else>  
            <p>Waiting socket<span class="loading-dots">...</span> </p>
        </template>
    </div>
</template>
<script>
import notice from '@/helpers/notice'
export default {
    name: 'Webradar',
    data() {
        return {
            reconnect: false,
            title: 'Webradar',
            socket: null,
            error: null,
            data: {},
        }
    },
    methods:{
        getKeys(){
            this.reconnect = false;
            let Token = localStorage.getItem('Token');
            this.socket = new WebSocket(`wss://radar.egmokka.com/ws/owner/${Token}`);
            this.socket.onopen = () => {
                console.log('connected')
            };
            this.socket.onmessage = (event) => {
                const data = event.data;
                if(data === 'timeout'){
                    notice.error({message:'Session timeout'})
                    this.reconnect = true;
                    this.socket.close();
                    return
                }
                if(data === 'failed'){
                    notice.error({message:'Authentication failed'})
                    this.socket.close();
                    return
                }
                try{
                    this.data = JSON.parse(data);
                }catch(e){
                    notice.error({message:'Invalid data: '+ e.message})
                }
            };
            this.socket.onerror = (error) => {
                this.socket.close();
                this.error = error.message;
            };
            this.socket.onclose = () => {
                this.socket = null;
            };
        }
    },
    mounted() {
        this.getKeys();
    },
    beforeUnmount() {
        if (this.socket) {
            this.socket.close();
        }
    },
}
</script>
<style scoped>
.loading-dots {
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  animation: loading-dots 1.5s infinite;
  width: 24px;
}

@keyframes loading-dots {
  0% { width: 0; }
  33% { width: 8px; }
  66% { width: 16px; }
  100% { width: 24px; }
}
</style>
